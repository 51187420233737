import React from 'react'
import about from "../assets/images/logo (2).png";
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-14 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[200px] car_outline' />
                            {/* <div className=' absolute bottom-2 sm: bottom-[-70px] right-[100px] md:bottom-[10px] right-1 translate-x-1/2 md:translate-x-0 md:right-[60px]  bg-white py-4 px-4 rounded-xl shadow-md'>
                                <div className=' flex flex-col gap-y-1 items-center'>
                                    <span className='primary-color text-4xl font-bold'>6+</span>
                                    <span className=' text-lg opacity-60 font-medium'>Years Experience</span>
                                </div>

                            </div> */}
                        </div>
                    </div>
                    <div className='mt-20 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4'>Leading in Taxi booking Services

</div>
                        <p className='text-black opacity-60 md:text-lg mt-5'>We're in this business Since 2018 and we provide the best services


</p>                     
                  <ul className='about-ul'>
                    <li className='about-li'>Easy Taxi booking in minutes</li>
                    <li className='about-li'>The best and transparent Pricing in the Market</li>
                    <li className='about-li'>Experienced skilled Drivers.

</li>

                 </ul>
                                        <div className=' mt-5'>
                        <a  href="tel:+917550090889"  className='th-btn'>Contact for More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About